<template>
  <div class="scroll-page">
    <Fixed  :navFixed="navFixed"></Fixed>
    <div class="scroll-titleBox" id="scroll-titleBox">
      <p style="font-size:27px; line-height:200%; margin:0pt 0pt 10pt;text-align: center;"><a
        name="_055-1599121612466"></a><span
        style="font-size:27px; font-weight:bold">移动智能终端生态联盟知识产权管理办法</span></p>
    </div>
    <div class="scroll-contentBox">
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt"><a name="_295-1599616064718"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt"><a name="_364-1599616064743"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0 0 10pt" id="navContent0"><a name="_916-1599616064745"></a><span
          style="font-size:14px;font-size:20px;font-weight:bold">第一章</span><span
          style="font-size:14px;font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px;font-size:20px;font-weight:bold">总则</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_029-1599616064747"></a><span
          style="font-size:14px;">第一条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">移动智能终端生态联盟（以下简称“联盟”）重视并尊重知识产权权利人的各项合法权益，同时也鼓励建立开放、透明、可预期的知识产权环境，促进相关的标准化及产业化运用。根据《移动智能终端生态联盟章程》制定本办法。本办法适用于联盟制定和修订技术标准的整个过程。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_027-1599616064754"></a><span
          style="font-size:14px;">第二条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本办法是联盟章程的必要组成部分，适用于联盟所有会员。</span>
      </p>
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_742-1599616459005"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0pt 0pt 10pt" id="navContent1"><a name="_712-1599616064758"></a><span
          style="font-size:20px;font-weight:bold">第二章</span><span
          style="font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:20px;font-weight:bold">定义</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_125-1599616064764"></a><span
          style="font-size:14px;">第三条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">除非另有规定，本办法中的词语定义如下:</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_030-1599616064764"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 1. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“会员”是指《移动智能终端生态联盟章程》第九条所规定的组成联盟的所有会员单位。本办法中的会员包括会员及其关联者。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_483-1599616064765"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 2. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“关联者”是指任何直接或间接被一方控制、与该方受共同控制、或者控制该方的任何法律实体；“控制”这一用语的含义指通过合同安排或其他方式直接或间接拥有至少百分之五十（50％）的投票权的公司股票、股权或其它证券，或直接或间接的拥有选举或委派董事会多数董事，或有权决定一方财务和经营政策，并能据以从该方的经营活动中获取利益。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_073-1599616064766"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 3. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“标准草案”是指联盟技术标准工作组提出的建议或文件草案，且标题为“标准草案”的。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_424-1599616064768"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 4. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“最终标准”是指被联盟理事会采纳和批准颁布的标准草案以及由联盟理事会采纳和批准颁布的对最终标准的更新或修订。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_395-1599616064769"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 5. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“符合部分”仅指有关产品或服务中执行并符合最终联盟标准的所有相关规范性要求的特定部分，这些规范性要求应当在最终标准中明确公开，并且其目的是为了使产品或服务能够实现该最终标准所提出的技术方案。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_424-1599616064768"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 6. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“必要专利”是指根据授权或公布专利的所在国法律，被实施最终标准的符合部分不可避免地侵犯的专利。专利不可避免地遭到侵犯，是指该侵权行为不可能在实施最终标准时通过采用另一个技术上可行的不侵权的实施方式予以避免。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_424-1599616064768"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 7. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“被许可方”：指经专利权人的直接或间接授权，获准实施权利人之专利的必要专利所覆盖的符合部分的法律实体。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_424-1599616064768"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 8. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“提案”：指以书面(包括电子媒体)形式向/为联盟技术标准工作组正式提交的任何材料、建议和其他相关提交文件，用于提议某一标准草案的全部或部分内容、或对标准草案或最终标准进行补充或修订。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_424-1599616064768"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 9. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">“参与”：指联盟技术标准工作组会员正式申请并且得到批准，在工作组完成某一标准草案前，该会员可以参加、提出提案或以其他方式接触到工作组的工作，而不管该会员是否事实上参加、提出提案或审阅了工作组的工作。</span></p>
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_750-1599616460410"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0pt 0pt 10pt" id="navContent2"><a name="_291-1599616064772"></a><span
          style="font-size:20px;font-weight:bold">第三章</span><span
          style="font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:20px;font-weight:bold">专利</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_094-1599616064775"></a><span
          style="font-size:14px;">第四条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本联盟标准的最终草案正式发布之前，应给予所有会员不少于三十天的审阅期，以便会员就该标准草案所涉及的知识产权问题进行全面审阅，会员应该根据诚信原则，就实施联盟标准所需要的必要专利，在审阅期间或联盟规定截止日期（不得晚于三十天的审阅期）前向联盟进行披露。会员披露的专利信息应当包括： </span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_030-1599616064764"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (1) </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">专利权人，专利申请号或专利授权号；</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_483-1599616064765"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (2) </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">涉及专利的标准信息（包括：标准名称、版本号、章节号（可选）等）。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_251-1599616064776"></a><span
          style="font-size:14px;">第五条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">联盟鼓励会员基于诚信原则尽早披露会员及其关联者所知悉的实施联盟标准所需要的必要专利（含专利申请）信息，而不论该必要专利和专利申请是否由该会员拥有或控制。该披露义务并不意味着会员有承担专利检索和分析的义务，而仅以会员派出参加标准制订工作的人员个人所知为基础。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_224-1599616064784"></a><span
          style="font-size:14px;">第六条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">如果非本联盟会员单位或个人持有必要专利引起本联盟注意的，联盟秘书长将代表理事会与该非联盟会员专利权利人积极联系，寻求有效途径获得相应的专利许可声明。如果该专利持有人拒绝在公平、合理、无歧视原则基础上许可标准实施者实施其必要专利或者在联系之日起3个月内未能同意许可，秘书长应提交理事会，经理事会成员单位一致同意后寻求其他解决方案，包括但不限于：</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_455-1599616064785"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (1)</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">如标准未发布，应寻求采用可行的替代方案；如无替代方案，应通知理事会进行表决是否暂停或停止该标准的研发和公布，同时寻求其他解决方案。 </span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_366-1599616064787"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (2)</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">如标准已经公布，应寻求采用可行的替代方案；如无替代方案，联盟应通过理事会表决停止该标准的应用推广或废止该标准。 </span></p>
      
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_817-1599616064793"></a><span
          style="font-size:14px;">第七条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员及其关联者向本联盟书面提交必要专利信息披露的同时，也应当向本联盟书面提交必要专利许可声明，该许可声明应当包括联盟会员同意在对其所持有的必要专利进行许可时，基于对等原则，在公平合理无歧视（FRAND）的基础上向联盟其他会员授予不可撤销的，非排他的，不可转让的，不可再许可的，全球范围的许可，以允许这些会员制造、委托制造、使用、许诺销售、销售、进出口符合部分。</span></p>
      
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_817-1599616064793"></a><span
          style="font-size:14px;">第八条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员在签署“联盟会员加入承诺书”的同时，应当默认同意按照本办法第七条的许可声明履行许可义务。如果会员针对标准草案中所涉及的一个或多个特定必要专利选择“拒绝在公平合理无歧视的基础上许可其所持有的必要专利”，该会员必须在标准草案审阅期间或联盟规定的截止日期（不得晚于三十天的审阅期）之前另行作出许可排除声明，并在许可声明中明确写明不提供许可的特定专利的相关信息，该信息包含：</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_838-1599616064794"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (1)</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">专利权人和/或申请人的身份；</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_510-1599616064795"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (2)</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">专利号或专利申请号；</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_476-1599616064796"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; (3)</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">涉及专利的标准信息（包括：标准名称、版本号、章节号（可选）等）。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_817-1599616064793"></a><span
          style="font-size:14px;">第九条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">联盟在收到会员的上述带有“拒绝在公平合理无歧视的基础上许可其所持有的必要专利”的声明后，应当由标准工作组对该标准草案进行相应修改；如果会员在其声明中未按本条款要求提供相关信息或者会员在审阅期截止日之前或联盟规定截止日期（不得晚于三十天的审阅期）前没有做出声明，就涉及的必要专利将适用本办法第七条的规定履行许可义务。会员及非会员向联盟提交的专利许可声明及本章程下的承诺均是不可撤销的，直到由于标准内容的变化导致已作出实施许可声明的必要专利对于标准的实施不再是必要的，会员与标准实施者可自行协商对该专利的许可进行变更或维持不变。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_817-1599616064793"></a><span
          style="font-size:14px;">第十条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员及其关联者转让必要专利所有权的，应将本联盟内的声明、承诺及相关义务纳入转让合同，由专利受让者继续遵守、履行。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_817-1599616064793"></a><span
          style="font-size:14px;">第十一条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本办法中的对等原则包括但不限于：当被许可方拒绝就其拥有或控制的必要专利向已经做出许可承诺的会员提供许可时，或者被许可方有违反在先合同义务情况出现时，该会员可以就其必要专利不向该被许可方提供许可或撤销已经授予该被许可方的许可。</span></p>
      
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_430-1599616466194"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0 0 10pt" id="navContent3"><a name="_920-1599616064831"></a><span
          style="font-size:20px;font-weight:bold">第四章</span><span
          style="font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:20px;font-weight:bold">版权</span></p>

      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_071-1599616064807"></a><span
          style="font-size:14px;">第十二条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员向联盟提交的与联盟标准相关的标准提案、标准草案、技术报告、解决方案、白皮书等文件的行为，视为不可撤销的、永久的、覆盖全球范围内的、免费的、非排他的、不可分许可的、不附带任何其他条件的许可本联盟及其他会员以制定、发布、推广相关技术标准、非盈利为目的使用其享有著作权的作品，但许可仅限于应用于以下目的及范围： </span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_165-1599616064809"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp;（一）</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">用于联盟内部的交流；</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_838-1599616064810"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp;（二）</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">用于标准技术的研发制定及评估； </span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_260-1599616064815"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp;（三）</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">用于标准的发布及推广；</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_796-1599616064820"></a><span
          style="font-size:14px;">第十三条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本联盟及其各工作机构所制定的标准、发布的报告、白皮书等输出文件的著作权归本联盟所有。联盟不可撤销的、永久的、覆盖全球范围内的、免费的、非排他的、不可分许可的、不附带任何其他条件的许可本联盟会员以非盈利为目的使用这些其享有著作权的作品。互认标准及合作制定标准的著作权按照互认协议及合作协议确定。非会员获取和使用本协会未发布的标准及文稿，需事先征得联盟同意。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_796-1599616064820"></a><span
          style="font-size:14px;">第十四条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">未经联盟同意，任何会员均不得出版或发行标准草案或最终标准的全部或部分内容、或其他任何演绎作品。</span></p>



      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_430-1599616466194"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0 0 10pt" id="navContent4"><a name="_920-1599616064831"></a><span
          style="font-size:20px;font-weight:bold">第五章</span><span
          style="font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:20px;font-weight:bold">商标和标识</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_570-1599616064833"></a><span
          style="font-size:14px;">第十五条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">以联盟名义申请或注册的相关商号、商标、标识、域名等，其知识产权应当归属联盟所有，并由联盟统一管理。</span><span
          style="font-size:14px; ">&#xa0;</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_188-1599616064836"></a><span
          style="font-size:14px;">第十六条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; "> 联盟相关标识根据使用范围以及标识作用的不同，分为联盟标识和产品认证标识两类。加入联盟的会员，在遵守联盟相关规定的前提下，可以在对外宣传推广活动中、公司网站上使用相应的联盟标识，用以表明该联盟的会员身份。任何人使用本联盟注册的商标，应当事先征得本联盟秘书处的书面同意。</span></p>
      
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_463-1599616064843"></a><span
          style="font-size:14px;">第十七条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">产品通过联盟认证的会员，可以在与联盟或其指定的实体签署基于公平合理无歧视性原则的商标授权使用协议的前提下，在其产品上使用产品认证标识。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_576-1599616064844"></a><span
          style="font-size:14px;">第十八条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">联盟作为商标权人有权对使用产品认证标识的产品进行抽检，对于抽检发现不能满足产品认证要求的产品，联盟有权要求该产品单位限期提交新产品进行认证，若认证结果仍不合格，联盟有权立即终止与该厂商签署的商标授权许可协议。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_050-1599616064846"></a><span
          style="font-size:14px;">第十九条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">联盟有权对联盟相关标识进行变更或修改。</span>
      </p>
      <p style="font-size:11pt; line-height:200%; margin:0pt 0pt 10pt"><a name="_913-1599616469422"></a><span
          style="font-size:11pt">&#xa0;</span></p>
      <p style="font-size:20px;line-height:200%; margin:0 0 10pt" id="navContent5"><a name="_685-1599616064866"></a><span
          style="font-size:20px;font-weight:bold">第六章</span><span
          style="font-size:20px;font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:20px;font-weight:bold">许可义务的存续</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_522-1599616064868"></a><span
          style="font-size:14px;">第二十条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员根据第七条、第八条提供许可的承诺在会员终止或撤销其在联盟的会员资格之后对于下列必要专利仍应继续有效：</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_030-1599616064764"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 1. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">与该会员就并入某一最终标准的任何版本的标准草案提出的提案有关的任何必要专利，条件是提供许可的承诺仅限于该标准草案，以及最终标准中为了向前兼容该标准草案所必需的部分；</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_483-1599616064765"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 2. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">该会员的与并入某一最终标准的任何版本的标准草案有关的其提案之外的任何必要专利，该标准草案在该会员的联盟会员资格存续期间可供其审阅，条件是该会员或前会员应有权在该标准草案可供审阅之日起三十天内根据第七条或第八条的规定做出声明，并且提供许可的承诺仅限于该标准草案，以及最终标准中为了向前兼容该标准草案所必需的部分。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_483-1599616064765"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp; 3. </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">与某一后续最终标准有关的必要专利，但仅限于与该最终标准以下部分有关的必要专利：（1）该部分是为了向前兼容，且在该会员的联盟会员资格存续期间被采纳的某一最终标准所必需的；（2）该会员就在先采纳的该最终标准对该必要专利已经承诺了许可义务。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt;color:#595959;"><a name="_483-1599616064765"></a><span
          style="font-size:14px; ">&nbsp;&nbsp;&nbsp;&nbsp;  </span><span
          style="font-size:14px; ">&#xa0;</span><span
          style="font-size:14px; ">除本条明确规定之外，撤销或终止会员资格的会员没有义务许可任何其他必要专利。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_117-1599616064873"></a><span
          style="font-size:14px;">第二十一条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">如果联盟解散，会员同意在解散后（根据第七条、第八条的规定）就必要专利提供许可，但仅限于该会员在解散之前有义务提供许可的最终标准。</span>
      </p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_999-1599616064875"></a><span
          style="font-size:14px;">第二十二条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员资格终止、联盟解散、或者本办法终止的，对于会员在此之前已经授予的专利许可没有任何影响，除非有关的专利许可合同另有约定。</span>
      </p>

      <p style="font-size:21pt; line-height:200%; margin:0 0 10pt" id="navContent6"><a name="_261-1599616064878"></a><span
          style="font-size:21pt; font-weight:bold">第七章</span><span
          style="font-size:21pt; font-weight:bold;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:21pt; font-weight:bold">其他</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_796-1599616064879"></a><span
          style="font-size:14px;">第二十三条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">所有会员同意，除了本办法中明确约定应提供的许可外，本办法并不构成会员就任何知识产权的许可、豁免或其他权利对任何其他方的授予或提供，不论是以直接或暗示、禁止反悔或其他的方式。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十四条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">对本办法的任何修改必须遵循联盟章程的有关规定。会员应当有至少三十天时间以决定是否接受有关修改（“接受期间”），该期间自会员接到有关修改的书面通知之日起算（可以通过电子邮件通知）。如果在审核期满时联盟未收到会员的书面通知，将视该会员已接受该修改版本。所有通过明示或者暗示方式接受修改版本的会员均需签署会员承诺书予以确认。在接受期间结束之前退出联盟的任何会员不受修改后的知识产权政策的约束。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十五条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">对于专利权利人提供给联盟的必要专利信息及专利许可声明，本联盟通过适当的程序和方式向会员及公众公开。本联盟不负责必要专利有效性及适用性审查，也不保证必要专利信息的真实性与完整性。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十六条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">除本办法另有规定外，本联盟不作任何明示或默示的保证，包括但不限于不侵犯第三方知识产权保证、标准实施产品的商品性保证和适用性保证等。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十七条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本联盟不对会员或者第三方承担任何因本办法造成的直接的、间接的、 特殊的、附带的、惩罚性的或连带的损害赔偿责任，不论该损害是关于合同、侵权、担保或其他方面，以及不论该会员或者第三方是否被提前告知了产生损害的可能性，损害赔偿责任包括但不限于购买替代产品或服务而产生的费用、利润损失、无法使用或数据丢失等。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十八条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本办法适用中华人民共和国法律并受其管辖和解释，且不考虑法律冲突。本办法的最终解释权归联盟所有。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第二十九条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">会员如有违背本办法规定的行为，可视为对本办法的违约，联盟有权采取合理措施进行救济，以维护联盟权益以及保证联盟发展目标的实施。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第三十条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">联盟不介入标准实施过程中的专利许可事宜，其应由专利权利人与标准实施者自行协商解决。对于因实施标准引起涉及专利问题的有关争议，由相应机构解决。</span></p>
      <p style="font-size:14px; line-height:200%; margin:0pt 0pt 10pt 21pt"><a name="_524-1599616064880"></a><span
          style="font-size:14px;">第三十一条</span><span
          style="font-size:14px;;width:10pt;display:inline-block;">&#xa0;</span><span
          style="font-size:14px; ">本办法经联盟理事会通过后自2022年6月20日开始施行，适用于联盟所有知识产权相关活动。针对本办法的任何修正案，其生效日为修正案公布之日，其效力与本办法等同。</span></p>
    </div>
  </div>
</template>

<script>
import Fixed from "@/components/Fixed";
export default {
  components: { Fixed },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 知识产权管理办法',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,兼容性,金标认证'
      },
      {
        name: 'description',
        content: '应用兼容性标准目的是提升安卓应用体验，营造良好的安卓应用使用环境，推动安卓应用软件运行在手机上的健康发展，建立安全可靠、值得信赖、健康的应用生态环境体系。'
      },
    ]
  },
  data() {
    return {
      navFixed: [
        {
          name:'第一章 : 总则',
          isActive:0,
          class:'#navContent0',
        },
        {
          name:'第二章 : 定义',
          isActive:null,
          class:'#navContent1',
        },
        {
          name:'第三章 : 专利',
          isActive:null,
          class:'#navContent2',
        },
        {
          name:'第四章 : 版权',
          isActive:null,
          class:'#navContent3',
        },
        {
          name:'第五章 : 商标和标识',
          isActive:null,
          class:'#navContent4',
        },
        {
          name:'第六章 : 许可义务的存续',
          isActive:null,
          class:'#navContent5',
        },
        {
          name:'第七章 : 其他',
          isActive:null,
          class:'#navContent6',
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-page {
  height: 100%;
  .scroll-titleBox{ 
    position: fixed;
    top: 80px;
    width: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #dde2e8;
    background: #f8f6f7;
    z-index: 6;
  }
  .scroll-contentBox{
    width:calc(100%-400px) ;
    border-left: 1px solid #dde2e8;
    padding: 2% 3% 5% 2%;
    margin-left: 300px;
  }
}
@media screen and (max-width: 800px) {
    .scroll-page {
      .scroll-titleBox{ 
        position: fixed;
        top: 120px;
        width: 100%;
        padding-top: 20px;
        border-bottom: 1px solid #dde2e8;
        background: #f8f6f7;
        z-index: 6;
      }
      .scroll-contentBox{
        box-sizing:border-box; 
        width:100%;
        padding:120px 8% 2% 5%;
        border-left:0;
        margin-top:100px;
        margin-left:0px;
      }
    }
}
</style>