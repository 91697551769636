<template>
    <div class="nav-fixed-box ">
      <div class="item-box">
        <div class="nav-fixed-item text-center"  v-for="(item,index) in navFixed" :key="index" @click="scrollView(item)">
          <div class="name" :class="{activeName:item.isActive==index}" >
            <p class="ellipsis">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props:['navFixed'],
    mounted() {
      window.addEventListener("scroll",this.handleScroll); 
    },
    destroyed() {
      document.removeEventListener('scroll', this.handleScroll)
    },
    
    methods: {
      //滚动检查
      handleScroll() {
        //获取滚动时的高度
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        for(let i=(this.navFixed.length-1);i>=0;i--){
          let item = this.navFixed[i];
          item.isActive=null;
            this.navFixed.forEach(item=>item.isActive=null);
          if (scrollTop < 200) { //初始化第一个
            this.navFixed[0].isActive=0;
            break;
          }else if (scrollTop+$(window).height()==$(document).height()) {//倒数一个
            this.navFixed[this.navFixed.length-1].isActive=this.navFixed.length-1;
            break;
          }else if (scrollTop+$(window).height()>$(document).height()-200) {//倒数第2个
            this.navFixed[this.navFixed.length-2].isActive=this.navFixed.length-2;
            break;
          }else if((scrollTop+200) > $(item.class).offset().top){
            //这是滑动到scrollTwo的距离要做的操作
            item.isActive=i;
            break;
          }
        }
      },
      //滚动到指定区域
      scrollView(item){
        $('body,html').animate({
          scrollTop:$(item.class).offset().top - ($('#head').outerHeight()+$('#scroll-titleBox').outerHeight())
        },300);
      }
    }
  };
</script>

<style scoped lang="scss">

  /*右侧栏*/
  .nav-fixed-box{
    position: fixed;
    left:0;
    top:0%;
    width:300px;
    min-height: calc(100% - 360px) ;
    font-size:15px;
    .item-box{
      margin-top: 170px;
      margin-left: 50px;
      width: 80%;
      .nav-fixed-item{
        padding: 15px 0;
        text-align: left;
        color: #000;
        .activeName{    
          color: #000;
          font-weight: bold;
        }
      }
      .nav-fixed-item:hover{
        cursor: pointer;
        font-weight: bold;
      }

    }
  }
  @media screen and (max-width: 800px) {
      .nav-fixed-box {
        display: none;
      }
  }
</style>
